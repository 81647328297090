// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-wapps-redirect-js": () => import("./@wapps/redirect.js" /* webpackChunkName: "component---cache-wapps-redirect-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-content-docs-privacy-js": () => import("./../src/pages/content/docs/privacy.js" /* webpackChunkName: "component---src-pages-content-docs-privacy-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

